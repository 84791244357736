import React, { useRef, useState } from "react";
import toast from "react-hot-toast";
import { motion } from "framer-motion";
import UseAnimationIos from "@/pages/api/UseAnimationIos";
import { imageScale } from "@/utils/motion";
import { useMediaQuery } from "@mui/material";
import { GilroyFontDark } from "@lib/GilroyFont";
import ClearIcon from "@mui/icons-material/Clear";
import useMouseOver from "@hooks/useMouseOver";
import { useRouter } from "next/router";
import AfterFormSubmit from "@components/AfterFormSubmit";
import { v4 as uuidv4 } from 'uuid';
import {
  options,
  BudgetValues,
  initialValues,
} from "../data/formData";
//import "react-phone-input-2/lib/style.css";

import { formValidationSchema } from "../utils/formValidations";

import useDynamicFormik from "./../hooks/useDynamicFormik";
import { sendLeadEvent } from "../utils/facebookEvent";
import timeStampGenerator from "./../lib/timeStampGenerator";
import {
  sendEmail,
  sendEmailToCustomer,
  sendDataToSheets4,
} from "./../lib/sendEmail";
import InputComponent from "./InputComponent";
import SelectComponent from "./SelectComponent";
import PhoneInputCustom from "./PhoneInputCustom";
import useClientIP from "@hooks/useClientIP";
import axios from "axios";

const GetStartedForm = ({
  isVisible,
  isAnimate,
  closeModal,
}) => {
  const [onScreen, setOnScreen] = useState(false);
 

  const ref = useRef();
  const formRef = useRef();
  UseAnimationIos(ref, setOnScreen);
  const animation = onScreen
    ? imageScale(1.5).animate
    : imageScale(0.5).initial;
  const ipAddress = useClientIP();

  const isMobile = useMediaQuery("(max-width:786px)");
  const isPixelActive = useMouseOver();
  const router = useRouter();

  const [selectedOption, setSelectedOption] = useState(null);
  const [selectedOptionBudget, setSelectedOptionBudget] = useState(null);
  const [selectProjectBegin, setSelectProjectBegin] = useState(null);
  const [selectHiringDecision, setSelectHiringDecision] = useState(null);
  const [isFormSubmited, setIsFormSubmited] = useState(false);
  const [isFormSubmitting, setisFormSubmitting] = useState(false);
  const [selectedTime, setSelectedTime] = useState("");

  const [isChecked, setIsChecked] = useState(true);

  const handleCheckboxChange = (event) => {
    setIsChecked(event.target.checked);

  };

 /* const handleTimeChange = (timeZone, timeSlot) => {
    const timeToCall = `${timeZone} ${timeSlot?.start ? timeSlot?.start : ""} - ${timeSlot?.end ? timeSlot?.end : ""}`;
    setSelectedTime(timeToCall);
  };
*/
async function sendconvertionEvent(eventName, eventData) {
  try {
    const response = await axios.post('/api/send-event', {
      eventName,
      userData: eventData.userData,
      customData: eventData.customData,
      clickId: eventData.clickId,
      userAgent: eventData.userAgent,
      browserId: eventData.browserId,
      externalId: eventData.externalId,
      ipAddress: eventData.ipaddress,
    });

    console.log('Event sent successfully:', response.data);
  } catch (error) {
    console.error('Error sending event:', error.response ? error.response.data : error.message);
  }
}
function getBrowserId() {
  // Logic to get Facebook Browser ID (fbp)
  const fbpCookie = document.cookie
    .split('; ')
    .find(row => row.startsWith('_fbp='));
  return fbpCookie ? fbpCookie.split('=')[1] : undefined;
}

function getClickId() {
  // Check for _fbc cookie
  const fbcCookie = document.cookie
    .split('; ')
    .find(row => row.startsWith('_fbc='));

  if (fbcCookie) {
    console.log('Returning _fbc from cookie:', fbcCookie.split('=')[1]);
    return fbcCookie.split('=')[1];
  } else {
    console.warn('No _fbc cookie found');
  }

  // Generate _fbc from fbclid query parameter if _fbc cookie does not exist
  const urlParams = new URLSearchParams(window.location.search);
  const fbclid = urlParams.get('fbclid');
  if (fbclid) {
    const subdomainIndex = 1; // Adjust this based on your domain structure
    const creationTime = Math.floor(new Date().getTime() / 1000); // Use current time as creation time
    const generatedFbc = `fb.${subdomainIndex}.${creationTime}.${fbclid}`;
    console.log('Generated _fbc from fbclid:', generatedFbc);
    return generatedFbc;
  } else {
    console.warn('No fbclid query parameter found');
  }

  // Generate a fallback _fbc value
  const subdomainIndex = 1;
  const creationTime = Math.floor(new Date().getTime() / 1000);
  const placeholderFbclid = '0000000000000000'; // Placeholder if no fbclid is found
  const fallbackFbc = `fb.${subdomainIndex}.${creationTime}.${placeholderFbclid}`;
  console.log('Generated fallback _fbc:', fallbackFbc);
  return fallbackFbc;
}

  const onSubmit = async (values) => {
    setisFormSubmitting(true);
    const form_service_type =
      selectedOption?.map((option) => option.label).join(", ") ||
      "";
    const form_service_type_budget = selectedOptionBudget?.value
      ? selectedOptionBudget.value
      : "";

    const form_service_type_projectBegin = selectProjectBegin?.value
      ? selectProjectBegin.value
      : "";

    const form_service_type_hiringDecision = selectHiringDecision?.value
      ? selectHiringDecision.value
      : "not selected";

    try {

      const utcMinus11Timestamp = timeStampGenerator();
      let split_data = utcMinus11Timestamp.split(', ')
let date_str = split_data[0]
let time_str = split_data[1]

const formatPhoneNumber = (phoneNumber) => {
  const cleaned = ('' + phoneNumber).replace(/\D/g, '');
  const match = cleaned.match(/^(\d{1})(\d{3})(\d{3})(\d{4})$/);
  if (match) {
      return `(${match[2]}) ${match[3]}-${match[4]}`;
  }
  return phoneNumber;
};

const formattedPhoneNumber = formatPhoneNumber(formik.values.phone);
const userAgent = navigator.userAgent;
      const clickId = getClickId(); // Replace with your actual click ID logic
      const browserId = getBrowserId(); // Function to get Facebook Browser ID (fbp)
      const externalId = uuidv4(); // Replace with your actual external ID logic
      const ipaddress = ipAddress; 

      if (isChecked) {

        await sendconvertionEvent('Lead', {
          userData: {
            fn: values.name,
            email: values.email,
            ph: formattedPhoneNumber,
          },
          customData: {
            content_type: 'Content Form Submit',
            page_path: router.pathname,
          },
          clickId,
          userAgent,
          browserId,
          externalId,
          ipaddress,
        });
      

        
    //  sendLeadEvent(
      //  "Lead",
      //  formik,
      //  "Content Form Submit",
      //  router.pathname,
     //   isPixelActive,
     // );

  

      const newRow = {
        Date: date_str,
         // Time: time_str,
         State: "",
         Source: "Get Started",
         Assigned: "",
         Status: "Fresh",
         Name: formik.values.name,
         Email: formik.values.email,
         Number: formattedPhoneNumber,
         Company: "",
         "Client\nNotes": `${form_service_type_budget} ${form_service_type ? ('/') : ('')}  ${form_service_type} ${formik.values.message ? ('/') : ('')} ${formik.values.message} ${ipAddress ? ('/') : ('')} ${ipAddress}`,
         // Services: form_service_type,
         // Message: formik.values.message,
         // IP: ipAddress,
      };

      const gSheets = await sendDataToSheets4(newRow);
      window.location.href = '/thankyou';
      formik.setStatus({
        success: true,
        message: "Form submitted successfully",
      });

      if (!isVisible) {
        close(false);
      }
      // toast.success("Thank you!, Our representative will contact You.");
      setIsFormSubmited(true);
      setisFormSubmitting(false);
      if (!isVisible) {
        close(false);
      }
      
      const emailResponse = await sendEmail(
        formik,
        form_service_type_budget,
        form_service_type,
        form_service_type_projectBegin,
        form_service_type_hiringDecision,
        newRow,
        ipAddress,
      );

      const sendEmailToUser = await sendEmailToCustomer(
        formik,
        form_service_type_budget,
        form_service_type,
        form_service_type_projectBegin,
        form_service_type_hiringDecision,
        newRow,
      );

      if (emailResponse.data === "Success") {
        formik.setStatus({
          success: true,
          message: "Form submitted successfully",
        });

        if (!isVisible) {
          close(false);
        }
        // toast.success("Thank you!, Our representative will contact You.");
        setIsFormSubmited(true);
        setisFormSubmitting(false);
        if (!isVisible) {
          close(false);
        }
      }
      else {
        formik.setStatus({ success: false, message: "Submission error" });

        toast.error("Please Retry again.");
      }
    } else {console.log("please select a checkbox")}
      // }
      // else {
      //     // Handle error
      //     console.error(data.message);
      //     toast.error("Please refill captcha");

      // }
    } catch (error) {
      /* eslint-disable */
      console.log(error.message);
      formik.setStatus({ success: false, message: error.message });
    }
  };

  const formik = useDynamicFormik(
    initialValues,
    formValidationSchema,
    onSubmit,
  );
  //const recaptchaRef = React.createRef();



  return (
    <motion.div ref={ref}>
      <motion.div
        ref={ref}
        initial={isAnimate ? imageScale(0.5).initial : false}
        animate={isAnimate ? animation : false}
        className="flex flex-col items-center sm:my-10"
      >
        <form
          ref={formRef}
          onSubmit={formik.handleSubmit}
          className="relative rounded-full sm:p-6 w-full flex flex-col "
        >
          {formik?.status?.success === true ? (
            <></>
          ) : (
            <div className=" flex justify-center items-center">
              <div className="flex flex-col justify-center   min-w-[60.5vw] max-w-[100vw] sm:max-w-[32vw] bg-white px-10 py-2  rounded-2xl ">
                <h2
                  className={`text-black text-[1.2rem] pt-[0rem] ${GilroyFontDark.className} `}
                >
                  Book a Consultation
                </h2>
                <label>
                <InputComponent
                  required={true}
                  className="w-[100%] border-b-[1px] border-gray-800 text-gray-800 outline-none"
                  value="name"
                  id="name2-input"
                  placeholder="Full Name"
                  formik={formik}
                />
                </label>
                <label>
                <InputComponent
                  required={true}
                  className="w-[100%] border-b-[1px] border-gray-800  sm:w-[100%]  text-gray-800 outline-none"
                  value="email"
                  id="email2-input"
                  placeholder="Email"
                  formik={formik}
                />
                </label>
                <label>
                <PhoneInputCustom formik={formik} />
                </label>
                <label>
                <SelectComponent
                id="options2-input"
                  className="mb-2  border-b-[1px] border-gray-800"
                  //required={true}
                  selectWidth="100%"
                  isMobile={isMobile}
                  defaultValue={formik.values["selectedOption"]}
                  onChange={(selectedOption) => {
                    formik.setFieldValue(
                      "selectedOption",
                      selectedOption
                        ? selectedOption.map((option) => option.value)
                        : [],
                    );
                    setSelectedOption(selectedOption);

                    if (formik.values.selectedOption !== null) {
                      formik.setFieldTouched("selectedOption", false);
                    }
                  }}
                  formikTouched={formik.touched["selectedOption"]}
                  formikError={formik.errors["selectedOption"]}
                  stringValue="selectedOption"
                  options={options}
                  placeholder="Choose Services"
                  aria-label="Choose Services"
                  instanceId="react-select-services2"
                  label="Choose Services"
                  multi={true}
                  mobileWidth="auto"
                  formik={formik}
                  value={selectedOption}
                />
                </label>
                <label>
                <SelectComponent
                id="budget-input"
                  className="mb-2 border-b-[1px] border-gray-800"
                  //required={true}
                  selectWidth="100%"
                  isMobile={isMobile}
                  defaultValue={formik.values["selectedOptionBudget"]}
                  onChange={(selectedOptionBudget) => {
                    formik.setFieldValue(
                      "selectedOptionBudget",
                      selectedOptionBudget ? selectedOptionBudget.value : "",
                    );
                    setSelectedOptionBudget(selectedOptionBudget);

                    if (formik.values.selectedOptionBudget !== "") {
                      formik.setFieldTouched("selectedOptionBudget", false);
                    }
                  }}
                  stringValue="selectedOptionBudget"
                  options={BudgetValues}
                  placeholder="Budget"
                  instanceId="react-select-budget3"
                  label="budget"
                  multi={false}
                  mobileWidth="auto"
                  formik={formik}
                  value={selectedOptionBudget}
                  formikTouched={formik.touched["selectedOptionBudget"]}
                  formikError={formik.errors["selectedOptionBudget"]}
                />
</label>
            

                <div className="mb-2 col-span-2">
                  <label>
                  <textarea
                    name="message"
                    id="message4"
                    className=" w-full sm:w-[100%] h-[100px]  border border-gray-800 px-2 py-[5px]  rounded-2xl bg-white  input-placeholder text-[13px]"
                    value={formik.values.message}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    rows={4}
                    placeholder="Type your message here"
                  />
                  </label>
                  {formik.touched.message && formik.errors.message ? (
                    <div className="error">{formik.errors.message}</div>
                  ) : null}
                </div>

                <div className="mb-2 col-span-2">
                <label>
            <input className="ml-2 "
             type="checkbox"
              checked={isChecked}
              onChange={handleCheckboxChange}
              required
            />
            <span className="ml-2 text-xs text-gray-700">
            I hereby give my consent to Techcelerant to use my number for sending me SMS messages
        </span>
      </label>
                </div>

              {isFormSubmited ? (
                  <button
                    disabled
                    type="submit"
                    className={`relative w-[100%]  cursor-not-allowed	 sm:min-w-[100%] h-[2.5rem] bg-gray-300 shadow-lg shadow-gray-500 text-black rounded-full cursor-pointer ${
                      isVisible === false ? "mt-5" : ""
                    }`}
                  >
                    Sent
                  </button>
                ) : (
                  <button
                    type="submit"
                    className={`relative w-[15rem] sm:min-w-[100%] h-[2.5rem] ${formik.isValid && !formik.isSubmitting ? "  form-buttom-submit  bg-violet-400" : "bg-gray-300"} text-white rounded-full cursor-pointer text-[13px]`}
                    disabled={!formik.isValid || formik.isSubmitting}
                  >
                    {formik.isSubmitting ? "Sending..." : "Submit"}
                  </button>
                )}
                
              </div>
            </div>
          )}

          <span className="cursor-pointer absolute bottom-[-4rem]   right-[8rem] sm:bottom-[-2.5rem] sm:right-[12rem]">
            <ClearIcon
              onClick={closeModal}
              sx={{ fontSize: 60, color: "white" }}
            />
          </span>
        </form>
      </motion.div>
    </motion.div>
  );
};

export default GetStartedForm;
