export const imageScale = () => {
  return {
    initial: {
      scale: 0.9,
      opacity: 0,
    },
    animate: {
      scale: 1,
      opacity: 1,

      transition: {
        duration: 0.5,
        type: "tween",
        stiffness: 70,
        damping: 60,
      },
    },
    exit: {
      scale: 0.5,
      opacity: 0,
      transition: {
        duration: 1.5,
      },
    },
  };
};

export const staggerContainer = (staggerChildren, delayChildren) => {
  return {
    hidden: {},
    show: {
      transition: {
        staggerChildren: staggerChildren,
        delayChildren: delayChildren || 0,
      },
    },
  };
};
